<template>
    <v-container>
      <v-row>
        <v-col cols="12" lg="5">
          <s-crud
            title="Usuarios"
            search-input
            :config="configUser"
            @save="save($event)"
            @rowSelected="rowSelected($event)"
          >
            <template v-slot:options>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" small elevation="0" @click="copyAllow">
                    <v-icon color="default" x-small>
                      fas fa-copy
                    </v-icon>
                  </v-btn>
                </template>
                <span>Copiar a</span>
              </v-tooltip>
            </template>
          </s-crud>
        </v-col>
        
        <v-col v-if="item">
          <s-toolbar label="Permisos" removed="deleteEvent()" remove excel>
            <v-text-field lg="5" 
                    v-model="searchText"
                    append-icon="mdi-magnify"
                    single-line
                    hide-details
                ></v-text-field>

          </s-toolbar>
          <v-card elevation="0">
            <v-col>
              <v-row justify="center">
                <v-col lg="10" class="pb-1" cols="12">
                  <s-select-area ref="usrcce" clearable label="Centro de Costo" centercost v-model="item.CceID" />
                </v-col>
                <v-col lg="6" class="pt-0" cols="12">
                  <s-select-business-line
                    clearable
                    label="Linea de Costo"
                    :cceID="item.CceID == null ? 0 : item.CceID"
                    v-model="item.BslID"
                  />
                </v-col>
                <v-col lg="4" class="pt-0" cols="12">
                  <s-select-definition :def="1045" label="Tipo de Costo" v-model="item.TypeCost" />
                </v-col>
              </v-row>
              <v-row justify="center">
                <v-col class="pt-1" lg="5"><v-btn @click="item = {}; $refs.usrcce.focus();" block small>Nuevo</v-btn></v-col>
                <v-col class="pt-1" lg="5"><v-btn @click="save(item)" block small color="primary">Guardar</v-btn></v-col>
              </v-row>
             
            </v-col>
          </v-card>
  
          <!-- Campo de búsqueda -->
         
  
          <!-- Data table con filtro -->
          <v-data-table
            show-select
            :items="filteredItemsPermit"
            dense
            item-key="PerID"
            :headers="config.headers"
            v-model="selectedPermit"
            :single-select="true"
          >
          </v-data-table>
        </v-col>
      </v-row>
  
      <v-dialog v-model="showDialogCopy" max-width="500">
        <v-card>
          <v-card-title>
            <label>
              Seleccione a quienes copiará los permisos de <strong>{{ (userSelected.UsrName || "").toUpperCase() }}</strong>
            </label>
          </v-card-title>
  
          <v-card-text>
            <s-user v-model="usersCopy" ref="usrcopy" :isCrud="false" :singleRow="false" />
          </v-card-text>
  
          <v-card-actions>
            <v-btn color="default" small text @click="closeDialogCopy()">
              Cerrar
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="success" dark depressed small @click="saveCopyPermit()">Copiar permisos </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-container>
  </template>
  
  <script>
  import _sPermit from "@/services/Logistics/PermitService";
  import _sUser from "@/services/Logistics/UserService";
  import sUser from "@/views/Security/UserOption/User.vue";
  
  export default {
    components: { sUser },
    data: () => ({
      itemsUsers: [],
      itemsPermit: [],
      item: null,
      selectedPermit: [],
      showDialogCopy: false,
      usersCopy: [],
      userSelected: {},
      searchText: '',  // Nueva variable para el texto de búsqueda
      config: {
        model: { PerID: "ID", CceID: "int" },
        headers: [
          { text: "Centro de Costo", value: "CceName" },
          { text: "Linea de Negocio", value: "BslName" },
          { text: "Tipo de Costo", value: "TypeCostName" },
        ],
        service: _sPermit,
      },
      configUser: {
        model: { UsrID: "ID" },
        headers: [
          { text: "ID", value: "UsrID" },
          { text: "Nombre", value: "NtpFullName" },
          { text: "Usuario", value: "UsrName" },
        ],
        service: _sUser,
      },
    }),
    computed: {
      // Filtrado de itemsPermit basado en el texto de búsqueda
      filteredItemsPermit() {
        if (!this.searchText) {
          return this.itemsPermit;
        }
        return this.itemsPermit.filter((item) =>
          item.CceName.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.BslName.toLowerCase().includes(this.searchText.toLowerCase()) ||
          item.TypeCostName.toLowerCase().includes(this.searchText.toLowerCase())
        );
      },
    },
    methods: {
      copyAllow() {
        this.showDialogCopy = true;
        this.usersCopy = [];
        this.$refs.usrcopy.refresh();
      },
      saveCopyPermit() {
        this.usersCopy = Object.assign([], this.usersCopy).filter(
          (x) => x.UsrID != this.userSelected.UsrID
        );
        _sPermit.copyallPermit(this.userSelected, this.usersCopy, this.$fun.getUserID()).then((r) => {
          this.$fun.alert("Permisos actualizados correctamente.", "success");
          this.closeDialogCopy();
        });
      },
      rowSelected(items) {
        if (items.length > 0) {
          this.item = items[0];
          this.item.TypeCost = 1;
          if (items.length > 0) {
            this.userSelected = items[0];
          }
          _sPermit.byUser({ UsrID: this.item.UsrID }, this.$fun.getUserID()).then((r) => {
            this.itemsPermit = r.data;
          });
        } else this.item = null;
      },
      deleteEvent() {
        if (this.selectedPermit.length > 0) {
          console.log(this.selectedPermit);
          this.selectedPermit[0].SecStatus = 0;
          _sPermit.save(this.selectedPermit[0], this.$fun.getUserID()).then((r) => {
            _sPermit.byUser({ UsrID: this.item.UsrID }, this.$fun.getUserID()).then((r) => {
              this.itemsPermit = r.data;
            });
          });
        }
      },
      closeDialogCopy() {
        this.showDialogCopy = false;
      },
      save(item) {
        if (!item.CceID) {
          this.$fun.alert("Falta Centro de Costo", "warning");
          return;
        }
        if (!item.BslID) {
          this.$fun.alert("Falta Linea de Negocio", "warning");
          return;
        }
        if (!item.TypeCost) {
          this.$fun.alert("Falta Tipo de Costo", "warning");
          return;
        }
        _sPermit.save(this.item, this.$fun.getUserID()).then((r) => {
          _sPermit.byUser({ UsrID: this.item.UsrID }, this.$fun.getUserID()).then((r) => {
            this.itemsPermit = r.data;
            this.item.CceID = 0;
            this.item.TypeCost = 1;
          });
        });
      },
    },
    created() {
      // _sPermit.users(this.$fun.getUserID()).then((r) => {
      //     this.itemsUsers = r.data;
      // });
    },
    mounted() {
      console.log("permiso de allline", this.$fun.hasSpecialPermission("AllLine"));
    },
  };
  </script>
  